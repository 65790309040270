import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {lastValueFrom, Observable} from 'rxjs';
import {CV_DUPLICATE_SUFFIX, ICv, ICV_MAX_LENGTHS} from '../../interfaces/cv';
import {map} from 'rxjs/operators';
import {IActivityLogEntry} from '../../interfaces/activity-log-entry';
import {ICvListItem} from '../../interfaces/cv-list-item';
import {ICvBaseResponse} from '../../interfaces/cv-base-response';
import {ServiceNames} from '../../interfaces/my7n-env-config';
import {AppConfig} from '../../app-config';
import {CvProfileStrengthFacadeService} from '../facades/cv-profile-strength.facade.service';


@Injectable()
export class CvService {
  readonly API_CV: string;
  readonly API_CV_NEW: string;
  readonly API_USER: string;

  constructor(private http: HttpClient,
              private appConfig: AppConfig,
              private cvProfileStrengthFacadeService: CvProfileStrengthFacadeService) {
    this.API_CV = appConfig.serviceUrl(ServiceNames.Core) + 'cvs/';
    this.API_CV_NEW = appConfig.serviceUrl(ServiceNames.Cv, 'v1') + 'cv/';
  }

  /**
   * Returns single CV
   * @param {number} id
   * @returns {Observable<ICv>}
   */
  getCv(id: number): Observable<ICv> {
    const LANGUAGE_SKILLS_CATEGORY_ID = 11;

    return this.http.get<ICv>(this.API_CV_NEW + id).pipe(
      map((cv: ICv) => {
        cv.PortraitRefreshToken = 'refresh_' + (performance.now());

        // Separate skills and languages
        cv.LanguageSkills = cv.Skills.filter((skill) => skill.CategoryId === LANGUAGE_SKILLS_CATEGORY_ID);
        cv.Skills = cv.Skills.filter((skill) => skill.CategoryId !== LANGUAGE_SKILLS_CATEGORY_ID);

        return cv;
      })
    );
  }

  renameCV(cv: ICv | ICvListItem, name: string): Promise<any> {
    return lastValueFrom(this.http.patch<ICvBaseResponse>(this.API_CV + cv.Id + '/title', {
      Title: name
    })).then((result) => {
      this.cvProfileStrengthFacadeService.queryProfileStrength(cv.Id);
      return result;
    });
  }

  /**
   *
   * @param {number} Id
   * @param {string} cvName Current CV name
   */
  duplicateCv(Id: ICv['Id'], cvName: string) {
    const tempName = cvName.substring(0, ICV_MAX_LENGTHS.CvName - CV_DUPLICATE_SUFFIX.length - 1) + CV_DUPLICATE_SUFFIX; // - 1 cause index

    return lastValueFrom(this.http.post(this.API_CV_NEW + Id + '/duplicate', {
      Name: tempName
    })).then((result) => {
      return result;
    }, (error) => {
      console.error('[CvService] Failed to duplicate CV', error);
      return Promise.reject(error);
    });
  }

  /**
   * @param id
   * @returns {Promise<Object>}
   */
  removeCv(id: ICv['Id']) {
    return lastValueFrom(this.http.delete(this.API_CV_NEW + id)).then(result => {
      return result;
    }, error => {
      console.error('[CvService] Failed to remove CV with id:' + id);
      return Promise.reject(error);
    });
  }

  // send to agent
  sendCvToAgent(cvId: number, data: any) {
    return lastValueFrom(this.http.put(this.API_CV + cvId + '/sendtoagent', data)).then((result) => {
      return result;
    }, (error) => {
      console.error('[CvService] Failed to send message to agent ', error);
      return Promise.reject(error);
    });
  }

  getCvActivityLog(cvId: number) {
    return lastValueFrom(this.http.get<Array<IActivityLogEntry>>(this.API_CV + cvId + '/activitylog')).then((result) => {
      return result;
    }, (error) => {
      console.error('[CvService] Error occurred during getting activity log');
      return Promise.reject(error);
    });
  }
}
