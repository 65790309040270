<div class="event-container flex flex-start-center flex-full" (click)="showEvent();">
  <div class="event-date flex flex-col md:flex-row">
    <div class="event-day typography-header font-bold flex flex-col flex-center-center">
      {{event.StartDate | defaultDate: 'two_digits_day'}}
    </div>
    <div class="event-date-details flex flex-col flex-stretch-center md:flex-stretch-start">
      <span class="event-month typography-small-paragraph font-bold">{{event.StartDate | defaultDate: 'short_month'}}</span>
      <span class="event-day-of-week typography-small-paragraph hidden md:inline">{{event.StartDate | defaultDate: 'short_day_of_week'}}</span>
    </div>
  </div>
  <div class="event-details flex flex-col grow shrink-0 basis-auto">
    <span class="event-title">{{event.Title | truncate:55}}</span>
    <span title="{{event.Location}}" class="event-location">{{event.Location}}</span>
  </div>
</div>
