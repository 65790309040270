import * as fromCvProfileStrength from './cv-profile-strength.reducer';
import {
  ActionReducerMap,
  createFeatureSelector
} from '@ngrx/store';

export const cvFeatureKey = 'cvModule';
export const cvProfileStrengthKey = 'profileStrength';

export interface CvModuleState {
  profileStrength: fromCvProfileStrength.CvProfileStrengthState;
}

export const reducers: ActionReducerMap<CvModuleState> = {
  profileStrength: fromCvProfileStrength.cvProfileStrenghtReducer,
};

// global cv module selectors
export const getCvModuleState =
  createFeatureSelector<CvModuleState>(cvFeatureKey);

export const getCvProfileStrengthState =
  createFeatureSelector<fromCvProfileStrength.CvProfileStrengthState>(
    cvProfileStrengthKey
);
